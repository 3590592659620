<template>
  <div class="mobile-detail">
    <div class="mobile-detail-container" style="margin-bottom:167px">
      <cube-scroll v-if="item">
        <div class="mobile-detail-box">
          <div class="mobile-detail-row">
            <div class="mobile-detail-label">总结时间</div>
            <div class="mobile-detail-value">{{formatDate(item.time)}}</div>
          </div>
          <div class="mobile-detail-row">
            <div class="mobile-detail-label">总结内容</div>
            <div class="mobile-detail-value" v-html="getHtml(item.memo)"></div>
          </div>
        </div>
        <List v-if="reply && reply.length>0">
          <ListItem v-for="(re, index) in reply" :key="index">
            <ListItemMeta :title="re.user_name+ ' '+formatDate(re.time,'MM-dd hh:mm')+(re.isread=='1'?'（已读）':'')" :description="re.content" />
          </ListItem>
        </List>
      </cube-scroll>
    </div>
    <div class="mobile-detail-footer" v-if="item">
      <Input type="textarea" v-model="content" :rows="4" placeholder="请输入回复内容..." style="width: 100%;margin:5px 0 15px;" />
      <Button style="margin-right: 12px" @click="gotoDetail()">查看完整计划</Button>
      <Button type="primary" @click="saveItemReply">回复</Button>
    </div>
  </div>
</template>

<script>
import { formatDate } from "@/utils/format.js";
import { getDictText, visitPlanState } from "@/utils/dict.js";
import { GetVisitPlanReview, ReplyDaily } from "@/api";
import { loadDetailAction, submitAction } from "@/utils/action.js";

export default {
  data: function () {
    return {
      id: "",
      item: null,
      reply: null,
      content: "",
      itemState: visitPlanState,
    }
  },
  methods: {
    formatDate: formatDate,
    getDictText: getDictText,
    getHtml: function (text) {
      return text ? text.replace(/\n/g, '<br/>') : "";
    },
    gotoDetail: function () {
      this.$router.push('/mobile/office/visitplandetail?id=' + this.item.pid);
    },
    saveItemReply: function () {
      var model = { type: 1, daily_id: this.item.id, content: this.content, order_name: "工作总结" };
      submitAction(this, ReplyDaily, { model: model }, () => { this.dataRequest(); });
    },
    dataRequest: function () {
      loadDetailAction(this, GetVisitPlanReview, { id: this.id }, (data) => {
        this.item = data.item;
        this.reply = data.reply;
        this.content = "";
      });
    },
  },
  mounted() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.dataRequest();
    }
  }
}
</script>